
import { getLogoSrc, isKZ } from '@utils/common'
import { SignupIndividual } from './signup/individual'

export const Signup = () => {
	return (
		<div className="sign-up_wrapper">
			<img className="logo" src={getLogoSrc()} alt="" />
			<div className="form-lk-wrapper form-signup">
				<div className="title">{isKZ ? 'Регистрация' : 'Заявка на Регистрацию'}</div>
				<SignupIndividual />
			</div>
		</div>
	)
}
